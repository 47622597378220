html,
body {
  font-size: 16px;
  overflow: hidden;

  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: Inter;
}
#__next {
  height: 100%;
}

.portal-sidebar-editor .quill {
  height: calc(100% - 42px);
}
.portal-sidebar-editor .ql-container {
  max-height: calc(100% - 42px);
}

.ql-disabled {
  border: none !important;
}

.ql-editor > * {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ql-editor > ul {
  padding: 0;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 16px;
  color: var(--chakra-colors-gray-500);
}

.ql-editor .token {
  padding: 2px 8px;
  border-radius: 16px;
}

.ql-editor .token.green {
  background-color: var(--chakra-colors-green-100);
  color: var(--chakra-colors-green-900);
}

.ql-editor .token.purple {
  background-color: var(--chakra-colors-purple-100);
  color: var(--chakra-colors-purple-900);
}

.ql-editor .token.blue {
  background-color: var(--chakra-colors-blue-100);
  color: var(--chakra-colors-blue-900);
}

.ql-editor .token.orange {
  background-color: var(--chakra-colors-orange-100);
  color: var(--chakra-colors-orange-900);
}

.ql-editor .token.gray {
  background-color: var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-900);
}

.ql-editor .token.cyan {
  background-color: var(--chakra-colors-cyan-100);
  color: var(--chakra-colors-cyan-900);
}

.ql-editor > ol {
  padding-left: 0.5em;
}

span[title^="microlink.io"] {
  display: none;
}

.carousel__inner-slide {
  height: 100%;
}

.carousel__inner-slide > :first-child {
  height: 100%;
}

.PylonChat-bubbleFrameContainer {
  left: 16px;
  bottom: 60px !important;
}

.PylonChat-chatWindowFrameContainer {
  left: 16px;
  bottom: 120px !important;
}
